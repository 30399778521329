/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from "react";
import "./Footer.css";

export default class Footer extends Component {

    clickButton(href) {
        window.location.href = href;
    }

    getDate() { 
        const date = new Date();
        return date.getFullYear(); 
      };

    render() {
        return (
            <div className="container pb-5 mb-5" id={this.props.id}>
                <div className="row text-white text-center text-md-left">
                    <div className="col-12 col-md-4">
                        <h2 className="font-weight-bolder mt-0 mt-md-5 py-5 py-lg-3 text-center text-lg-left ">MENU</h2>
                        <button onClick={this.clickButton.bind(this, "#start")} className="mt-5 mx-auto mx-md-0">START</button>
                        <button onClick={this.clickButton.bind(this, "#about_us")} className="mt-1 mx-auto mx-md-0">O NAS</button>
                        <button onClick={this.clickButton.bind(this, "#offer")} className="mt-1 mx-auto mx-md-0">OFERTA</button>
                        <button onClick={this.clickButton.bind(this, "#team")} className="mt-1 mx-auto mx-md-0">TEAM</button>
                        <button onClick={this.clickButton.bind(this, "#apply")} className="mt-1 mx-auto mx-md-0">DOŁĄCZ</button>
                        <button onClick={this.clickButton.bind(this, "#footer")} className="mt-1 mx-auto mx-md-0">KONTAKT</button>
                    </div>
                    <div className="col-12 col-md-4">
                        <h2 className="font-weight-bolder mt-0 mt-md-5 py-5 py-lg-3 text-center text-lg-left ">KONTAKT</h2>
                        <a href="tel:+48 608 072 568" className="footerButton font-weight-light mt-0 mt-md-5">
                            <i className="fas fa-phone"></i> +48 608 072 568
                        </a>
                        <a href="mailto:zdun@zduntri.pl" className="footerButton font-weight-light">
                            <i className="fas fa-at"></i> zdun@zduntri.pl
                        </a>
                    </div>
                    <div className="col-12 col-md-4">
                        <h2 className="font-weight-bolder mt-0 mt-md-5 py-5 py-lg-3 text-center text-lg-left ">ZNAJDŹ NAS</h2>
                        <a href="https://www.facebook.com/zduntriathlonteam/" className="footerButton font-weight-light mt-5" target="_blank">
                            <i className="fab fa-facebook"></i> FACEBOOK
                        </a>
                        <a href="https://www.instagram.com/zduntri.team/" className="footerButton font-weight-light" target="_blank">
                            <i className="fab fa-instagram"></i> INSTAGRAM
                        </a>
                    </div>
                </div>
                <div className="text-center text-md-right text-white font-weight-light mt-5 pt-5">
                    Copyright © {this.getDate()} ZDUN
                </div>
            </div>
        );
    }
}