import React, { Component } from "react";
import "./ApplyForm.css";

export default class ApplyForm extends Component {

    constructor(props) {
        super(props);

        this.checkValidity = this.checkValidity.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.state = {
            surname: "",
            telephone: "",
            city: "",
            discipline: "",
            goal: "",
            rodo: false,
            surnameError: "",
            telephoneError: "",
            cityError: "",
            disciplineError: "",
            goalError: "",
            rodoError: "",
            firstType: true,
            sent: false
        }
    }

    handleChange(e) {
        const isCheckbox = e.target.type === "checkbox";
        this.setState({
           [e.target.name]: isCheckbox ? e.target.checked : e.target.value
        }, () => {
            if (!this.state.firstType)
                this.checkValidity();
        });
    }

    checkValidity() {
        let surnameError = "";
        let telephoneError = "";
        let cityError = "";
        let disciplineError = "";
        let goalError = "";
        let rodoError = "";

        if (this.state.surname.length < 3)
            surnameError = 'Imię jest za krótie.';

        if (this.state.telephone.length < 9)
            telephoneError = 'Numer telefonu jest za krótki';

        if (!this.state.telephone.match(/^[\d+]{6,}$/))
            telephoneError = 'Wprowadź poprawny numer telefonu';

        if (this.state.city.length < 3)
            cityError = 'Nazwa miasta jest za krótka';

        if (this.state.discipline.length < 3)
            disciplineError = 'Nazwa dyscypliny jest za krótka';

        if (this.state.goal.length < 3)
            goalError = 'Dokładnie opisz swój cel';

        if (!this.state.rodo)
            rodoError = 'Zaakceptuj';

        this.setState({
            surnameError,
            telephoneError,
            cityError,
            disciplineError,
            goalError,
            rodoError
        });

        if (
            surnameError ||
            telephoneError ||
            cityError ||
            disciplineError ||
            goalError ||
            rodoError
        )
            return false;

        return true;
    }

    handleSubmit(e) {
        const isValid = this.checkValidity();

        this.setState({
            firstType: false
        });

        if (isValid)
        {
            this.setState({
                sent: true
            });

            var formData = new FormData();

            formData.append("surname", this.state.surname);
            formData.append("telephone", this.state.telephone);
            formData.append("city", this.state.city);
            formData.append("discipline", this.state.discipline);
            formData.append("goal", this.state.goal);
            formData.append("rodo", this.state.rodo);

            var request = new XMLHttpRequest();
            request.open("POST", "/Mailer.php");
            request.send(formData);
        }

        window.location = "#apply";

        e.preventDefault();
    }

    render() {
        return (
            <div className="py-3" id={this.props.id}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-6 text-center text-md-left">
                            <h1 className="font-weight-bolder py-5 py-lg-3 text-center text-lg-left ">DOŁĄCZ DO NAS!</h1>
                            <p className="w-75 text-justify pt-3 mx-auto mx-lg-0">
                                PODEJMUJESZ NASZE WYZWANIE? ZAPISZ SIĘ NA BEZPŁATNY TRENING I WEJDŹ DO GRY. ABY ZACZĄĆ,
                                WYPEŁNIJ PONIŻSZY FORMULARZ
                            </p>
                            {this.state.sent ? (
                                <div className="alert alert-success">
                                    Wiadomość została wysłana!
                                </div>
                                ) : (
                                <form
                                    id="applyForm"
                                    className="mt-5 mt-md-3"
                                    onSubmit={this.handleSubmit}>

                                    <label htmlFor="surname">TWOJE IMIĘ I NAZWISKO</label>
                                    <input
                                        type="input"
                                        name="surname"
                                        id="surname"
                                        onChange={this.handleChange}
                                        className={this.state.surnameError ? "invalid" : ""}
                                    />

                                    {this.state.surnameError ? (
                                        <small>{this.state.surnameError}</small>
                                    ) : null}

                                    <label htmlFor="phone">NUMER TELEFONU</label>

                                    <input
                                        type="input"
                                        name="telephone"
                                        id="phone"
                                        onChange={this.handleChange}
                                        className={this.state.telephoneError ? "invalid" : ""}
                                    />

                                    {this.state.telephoneError ? (
                                        <small>{this.state.telephoneError}</small>
                                    ) : null}

                                    <label htmlFor="city">GDZIE MIESZKASZ?</label>

                                    <input
                                        type="input"
                                        name="city"
                                        id="city"
                                        onChange={this.handleChange}
                                        className={this.state.cityError ? "invalid" : ""}
                                    />

                                    {this.state.cityError ? (
                                        <small>{this.state.cityError}</small>
                                    ) : null}

                                    <label htmlFor="discipline">JAKA DYSCYPLINA CIĘ INTERESUJE? <br/> (NP. TRIATHLON, KOLARSTWO, BIEGANIE)</label>

                                    <input
                                        type="input"
                                        name="discipline"
                                        id="discipline"
                                        onChange={this.handleChange}
                                        className={this.state.disciplineError ? "invalid" : ""}
                                    />

                                    {this.state.disciplineError ? (
                                        <small>{this.state.disciplineError}</small>
                                    ) : null}

                                    <label htmlFor="goal">JAKI JEST TWÓJ CEL SPORTOWY?</label>

                                    <textarea
                                        name="goal"
                                        rows="6"
                                        id="goal"
                                        maxLength="255"
                                        onChange={this.handleChange}
                                        className={this.state.goalError ? "invalid" : ""}
                                    >
                                </textarea>

                                    {this.state.goalError ? (
                                        <small>{this.state.goalError}</small>
                                    ) : null}

                                    <label className="rodoLabel pt-4">
                                        <input
                                            type="checkbox"
                                            name="rodo"
                                            onChange={this.handleChange}
                                        />

                                        <span className="checkmark"></span>
                                        <p className="text-justify w-100 w-md-75">
                                        <span className={this.state.rodoError ? "invalidText" : ""}>
                                        Wyrażam zgodę na przetwarzanie moich, powyższych danych osobowych tylko i
                                        wyłącznie w celu umożliwienia przyszłego kontaktu. Na życzenie klineta dane
                                        te mogą zostać zmienione bądź usunięte w każdej chwili. Oświadczam, że dane te
                                        przekazuję dobrowolnie oraz jestem w pełni świadomy zakrsu ich przetwarzania.
                                        </span>
                                        </p>
                                    </label>

                                    <div className="w-100 w-md-75 text-center">
                                        <input type="submit" className="btn btn-outline joinButton px-3 my-3 text-center" value="WYŚLIJ" />
                                    </div>
                                </form>
                            )}

                        </div>
                        <div className="col-12 col-lg-6 text-center py-5 my-auto">
                            <img src="/img/rise_up.png" className="applyFormImage" alt="WYKRES POKAZUJĄCY WZROST" />
                            <h4 className="text-center py-5">OBIERZ KURS NA SUKCES!</h4>
                            <div className="row">
                                <div className="col-2 coloredList text-right">
                                    <ul>
                                        <li>1</li>
                                        <li>2</li>
                                        <li>3</li>
                                    </ul>
                                </div>
                                <div className="col-10">
                                    <ul className="nextToApply">
                                        <li>Wypełnij i wyślij formularz zgłoszeniowy.</li>
                                        <li>Poczekaj na kontakt trenera.</li>
                                        <li>Ustal swój indywidualny plan i do dzieła!</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}