import React, { Component } from "react";
import JoinButton from "./JoinButton";
import "./Team.css";

export default class Team extends Component {
    render() {
        return (
            <>
                <div className="container text-white" id={this.props.id}>
                    <h1 className="font-weight-bolder pt-3">TEAM</h1>
                </div>
                <div className="container-fluid p-0">
                    <div className="teamImage">
                        <div className="waveTop">
                        </div>
                        <div className="waveCenter">
                        </div>
                        <div className="waveBottom">
                        </div>
                    </div>
                </div>
                <div className="teamJoinButton mt-3 mt-md-0">
                    <JoinButton mdlarge />
                </div>
                <div className="partners text-center text-white">
                    <h4 className="py-3 py-md-5">PARTNERZY:</h4>
                    <div className="row p-0 m-0 text-center d-flex justify-content-center justify-content-lg-between">
                        <div className="col-6 col-md-2 p-0 m-0 d-flex align-items-center justify-content-center">
                            <img src="/img/partners_logotype_1.png" alt="LOGOTYP PARTNERA" />
                        </div>
                        <div className="col-6 col-md-2 p-0 m-0 d-flex align-items-center justify-content-center">
                            <img src="/img/partners_logotype_2.png" alt="LOGOTYP PARTNERA" />
                        </div>
                        <div className="col-6 col-md-2 p-0 m-0 d-flex align-items-center justify-content-center">
                            <img src="/img/partners_logotype_3.png" alt="LOGOTYP PARTNERA" />
                        </div>
                        <div className="col-6 col-md-2 p-0 m-0 d-flex align-items-center justify-content-center">
                            <img src="/img/partners_logotype_4.png" alt="LOGOTYP PARTNERA" />
                        </div>
                        <div className="col-6 col-md-2 p-0 m-0 d-flex align-items-center justify-content-center">
                            <img src="/img/partners_logotype_6.png" alt="LOGOTYP PARTNERA" />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}