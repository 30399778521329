import React, { Component } from "react";
import { Button } from "reactstrap";
import "./JoinButton.css";

export default class JoinButton extends Component {
    render() {
        var buttonClass = "joinButton joinButtonAnimate";

        if (this.props.large)
            buttonClass = "joinButton joinButtonAnimate btn-lg";

        if (this.props.lglarge)
            buttonClass = "joinButton joinButtonAnimate btn-lg";

        return(
            <a href="#apply">
                <Button outline className={buttonClass}>DOŁĄCZ DO NAS!</Button>
            </a>
        );
    }
}