import React, { Component } from "react";
import "./Trainings.css";

export default class Trainings extends Component {

    constructor(props) {
        super(props);

        this.getTrainings = this.getTrainings.bind(this);

        this.state = {
            data: []
        };
    }

    componentWillMount() {
        var self = this;
        var xmlhttp = new XMLHttpRequest();
        var url = "/getTraining.php";

        xmlhttp.onreadystatechange = function() {
            if (this.readyState === 4 && this.status === 200) {
                if (this.responseText)
                {
                    var data = [];
                    var myArr = JSON.parse(this.responseText);
                    myArr.forEach(e => {
                        data.push({
                            where: e.gdzie,
                            what: e.co,
                            when: e.kiedy,
                            time: e.o_ktorej
                        });
                    });

                    self.setState({data});
                }

            }
        };
        xmlhttp.open("GET", url, true);
        xmlhttp.send();
    }

    getTrainings() {
        var response = [];
        var i = 0;

        this.state.data.forEach(e => {
            var div_key = 'trainings_' + i;
            response.push(
                <div className="row" key={div_key}>
                    <div className="col-3">{e['where']}</div>
                    <div className="col-3">{e['what']}</div>
                    <div className="col-3">{e['when']}</div>
                    <div className="col-3">{e['time']}</div>
                </div>
            );
            i++;
        });

        if (!i)
            response.push(<div className="row" key="trainings_0"><div className="col-12 text-white">Brak informacji o najbliższych treningach.</div></div>);


        return response;
    }

    render() {
        return (
              <div className="container pb-4" id={this.props.id}>
                  <h2 className="pt-3">NAJBLIŻSZE TRENINGI</h2>
                  <div className="trainingsTable my-4 table">
                      <div className="thead-light">
                          <div className="row">
                              <div className="col-3">GDZIE</div>
                              <div className="col-3">CO</div>
                              <div className="col-3">KIEDY</div>
                              <div className="col-3">O KTÓREJ</div>
                          </div>
                      </div>
                      <div className="text-body">
                      {this.getTrainings()}
                      </div>
                  </div>
              </div>
        );
    }
}