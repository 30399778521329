import React, { Component } from "react";
import "./Offer.css";

export default class Offer extends Component {

    clickButton(href) {
        window.location.href = href;
    }

    render() {
        return (
            <div className="container-fluid" id="offer">
                <div className="container pb-5 mb-5">
                    <div className="row font-weight-light pt-4 mt-4">
                        <div className="col-12 col-lg-6">
                            <h3 className="my-4 font-weight-bold own_color">TRIATHLON</h3>
                            <div className="box_line pb-3 my-4">
                                <p className="font-weight-bold mb-0">Pakiet podstawowy:</p>
                                <ul className="text-justify px-4">
                                    <li>grupowy trening biegowy 4x/ miesiąc</li>
                                    <li>grupowy trening kolarski 1x/ miesiąc</li>
                                    <li>grupowy plan treningowy sporządzany co tydzień poprzez arkusze Google</li>
                                </ul>
                                Cena: <b>100 PLN/miesięcznie</b>
                            </div>
                            <div className="box_line pb-3 my-4">
                                <p className="font-weight-bold mb-0">Pakiet standard:</p>
                                <ul className="text-justify px-4">
                                    <li>grupowy trening biegowy 4x/ miesiąc</li>
                                    <li>grupowy trening kolarski 1x/ miesiąc</li>
                                    <li>plan treningowy sporządzany co 2 tygodnie, zgodnie z zadeklarowaną dyspozycyjnością treningową (wymagane określenie dyspozycyjności na min. 2 tygodnie)</li>
                                    <li>korekta planu treningowego (1x/ miesiąc)</li>
                                    <li>konsultacja treningowa online lub telefoniczna (1x/ miesiąc)</li>
                                    <li>sporządzanie planu treningowego w oparciu o posiadane przez zawodnika urządzenia pomiarowe</li>
                                    <li>plan treningowy sporządzany poprzez arkusze Google lub możliwość sporządzenia planu treningowego za pośrednictwem platformy TrainingPeaks</li>
                                    <li>informacja drogą elektroniczną nt. indywidualnej kontroli postępów treningowych na koniec każdego podokresu w rocznym cyklu treningowym (3x/ rok)</li>
                                    <li>konsultacja podczas ustalania kalendarza startów (1x/ 6 miesięcy)</li>
                                </ul>
                                Cena: <b>200 PLN/miesięcznie</b>
                            </div>
                            <div className="box_line mobile_online pb-3 mt-4">
                                <p className="font-weight-bold mb-0">Pakiet rozszerzony:</p>
                                <ul className="text-justify px-4">
                                    <li>grupowy trening biegowy 4x/ miesiąc</li>
                                    <li>grupowy trening kolarski 1x/ miesiąc</li>
                                    <li>zajęcia indywidualne 1x/ miesiąc (pływanie lub rower lub bieg)</li>
                                    <li>plan treningowy sporządzany co tydzień, zgodnie z zadeklarowaną dyspozycyjnością treningową (wymagane określenie dyspozycyjności na min. 1 tydzień)</li>
                                    <li>korekta planu treningowego (wg. potrzeb)</li>
                                    <li>konsultacja treningowa online lub telefoniczna (wg. potrzeb)</li>
                                    <li>sporządzanie planu treningowego w oparciu o posiadane przez zawodnika urządzenia pomiarowe</li>
                                    <li>plan treningowy sporządzany poprzez arkusze Google  lub możliwość sporządzenia planu treningowego za pośrednictwem platformy TrainingPeaks</li>
                                    <li>informacja drogą elektroniczną nt. indywidualnej kontroli postępów treningowych 1x/ 2 miesiące, lub spotkanie w celu omówienia postępów i planów rozwoju sportowego</li>
                                    <li>wspólny wyjazd na wybrany start (do wcześniejszego ustalenia, 1x/ pół roku)</li>
                                    <li>konsultacja podczas ustalania kalendarza startów (wg. potrzeb)</li>
                                </ul>
                                Cena: <b>350 PLN/miesięcznie</b>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 my-auto pt-4 pt-md-0 px-30">
                            <h3 className="my-4 font-weight-bold own_color">BIEGANIE</h3>
                            <div className="box_line pb-3 my-4">
                                <p className="font-weight-bold mb-0">Pakiet podstawowy:</p>
                                <ul className="text-justify px-4">
                                    <li>grupowy trening biegowy 4x/ miesiąc</li>
                                    <li>grupowy plan treningowy sporządzany co tydzień poprzez arkusze Google</li>
                                </ul>
                                Cena: <b>80 PLN/miesięcznie</b>
                            </div>
                            <div className="box_line pb-3 my-4">
                                <p className="font-weight-bold mb-0">Pakiet standard:</p>
                                <ul className="text-justify px-4">
                                    <li>grupowy trening biegowy 4x/ miesiąc</li>
                                    <li>plan treningowy sporządzany co 2 tygodnie, zgodnie z zadeklarowaną dyspozycyjnością treningową (wymagane określenie dyspozycyjności na min. 2 tygodnie)</li>
                                    <li>korekta planu treningowego (1x/ miesiąc)</li>
                                    <li>konsultacja treningowa online lub telefoniczna (1x/ miesiąc)</li>
                                    <li>sporządzanie planu treningowego w oparciu o posiadane przez zawodnika urządzenia pomiarowe</li>
                                    <li>plan treningowy sporządzany poprzez arkusze Google lub możliwość sporządzenia planu treningowego za pośrednictwem platformy TrainingPeaks</li>
                                    <li>informacja drogą elektroniczną nt. indywidualnej kontroli postępów treningowych na koniec każdego podokresu w rocznym cyklu treningowym (3x/ rok)</li>
                                    <li>konsultacja podczas ustalania kalendarza startów (1x/ 6 miesięcy)</li>
                                </ul>
                                Cena: <b>120 PLN/miesięcznie</b>
                            </div>
                            <h3 className="my-4 font-weight-bold own_color">KOLARSTWO</h3>
                            <div className="box_line pb-3 my-4">
                                <p className="font-weight-bold mb-0">Pakiet standard:</p>
                                <ul className="text-justify mx-0 px-4">
                                    <li>plan treningowy sporządzany co 2 tygodnie, zgodnie z zadeklarowaną dyspozycyjnością treningową (wymagane określenie dyspozycyjności na min. 2 tygodnie)</li>
                                    <li>korekta planu treningowego (1x/ miesiąc)</li>
                                    <li>konsultacja treningowa online lub telefoniczna (1x/ miesiąc)</li>
                                    <li>sporządzanie planu treningowego w oparciu o posiadane przez zawodnika urządzenia pomiarowe</li>
                                    <li>plan treningowy sporządzany poprzez arkusze Google lub możliwość sporządzenia planu treningowego za pośrednictwem platformy TrainingPeaks</li>
                                    <li>informacja drogą elektroniczną nt. indywidualnej kontroli postępów treningowych na koniec każdego podokresu w rocznym cyklu treningowym (3x/ rok)</li>
                                    <li>konsultacja podczas ustalania kalendarza startów (1x/ 6 miesięcy)</li>
                                </ul>
                                Cena: <b>120 PLN/miesięcznie</b>
                            </div>
                            <h3 className="my-4 font-weight-bold own_color">OFERTA TRENINGÓW INDYWIDUALNYCH ORAZ MAŁYCH GRUP WARSZTATOWYCH</h3>
                            <div>
                                <p className="font-weight-bold mb-0">Zajęcia indywidualne:</p>
                                <ul className="list-unstyled">
                                    <li>75 zł - 1 h (trening biegowy/trening kolarski/trening strefy zmian)</li>
                                    <li>75 zł - 45 min (trening pływania)</li>
                                </ul>
                            </div>
                            <div>
                                <p className="font-weight-bold mb-0">Treningi grupowe (2- 4os.):</p>
                                <ul className="list-unstyled">
                                    <li>45 zł - 1 h (trening biegowy/kolarski/trening strefy zmian)</li>
                                    <li>45 zł - 45 min (trening pływania*)</li>
                                </ul>
                                <p className="">*do kosztu zajęć wliczona została opłata za rezerwację toru i wstęp za basen</p>
                            </div>
                        </div>
                        
                    </div>
                    <div className="row pt-4 mt-4">
                        
                    </div>
                </div>
            </div>
        );
    }
}