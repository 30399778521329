import React, { Component } from "react";
import NavbarMenu from "./NavbarMenu";
import HeroUnit from "./HeroUnit";
import Trainings from "./Trainings";
import AboutUs from "./AboutUs";
import Offer from "./Offer";
import Team from "./Team";
import ApplyForm from "./ApplyForm";
import Footer from "./Footer";

import "./App.css";
import "./fontawesome-free-5.10.1-web/css/all.min.css";

class App extends Component {
    render() {

        return (
            <>
                <NavbarMenu />
                <HeroUnit id="start" />
                <Trainings id="trainings"/>
                <AboutUs id="about_us"/>
                <Offer id="offer"/>
                <Team id="team" />
                <ApplyForm id="apply" />
                <Footer id="footer" />
            </>
        );
    }
}

export default App;