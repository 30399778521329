import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import 'bootstrap/dist/css/bootstrap.min.css';

var destination = document.querySelector('#root');

ReactDOM.render(
    <App />,
    destination
);