import React, { Component } from "react";
import { Jumbotron } from "reactstrap";
import "./HeroUnit.css";
import JoinButton from "./JoinButton";

export default class HeroUnit extends Component {
    render() {
        return(
            <div id={this.props.id}>
                <Jumbotron className="heroUnit">
                    <div className="gradient text-center">
                        <h1 className="pt-2 pt-md-5 mt-md-4 font-weight-bold">
                            TRENUJ RAZEM Z ZDUNTRI.PL<br/>
                            I ZREALIZUJ SWOJE SPORTOWE CELE
                        </h1>
                        <h4 className="py-2 py-md-4 mt-md-3">PIERWSZY TRENING ZA DARMO!</h4>
                        <div className="mt-md-4">
                            <JoinButton lglarge/>
                        </div>
                    </div>
                </Jumbotron>
            </div>
        );
    }
}