import React, { Component } from "react";
import "./NavbarMenu.css";
import {
    Navbar,
    NavbarBrand,
    NavItem,
    Nav,
    Collapse,
    NavbarToggler,
    NavLink
} from "reactstrap";

export default class NavbarMenu extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.hideMenu = this.hideMenu.bind(this);

        this.state = {
            isOpen: false,
            mobile: false,
            collapsed: 0
        };
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        window.addEventListener("click", this.toggle);
        this.resize();
    }

    resize() {
        var fullHeight = window.innerHeight;
        var navbarHeight = document.querySelector('nav').offsetHeight;

        this.setState({
            collapsed: fullHeight - navbarHeight
        });

        if (window.innerWidth > 760) {
            this.setState({
                mobile: false
            });
        } else {
            this.setState({
                mobile: true
        });
        }
    }

    toggle(e) {
        if (e.target.className !== 'navbar-toggler-icon') {
            this.setState({
                isOpen: false
            });
        } else {
            this.setState({
                isOpen: !this.state.isOpen
            });
        }
    }

    hideMenu(e) {
        this.setState({
            isOpen: false
        });
    }

    render() {

        var navLinkClasses = "navLink text-center text-md-left no-wrap px-2 px-lg-3";
        var navClasses = {
            marginLeft: "auto"
        };

        if (this.state.mobile)
            navClasses = {
                marginLeft: "auto",
                height: this.state.collapsed
            };

        return (
            <Navbar dark className="navbar py-md-0 sticky-top" expand="md">
                <NavbarBrand href="/"><img src="/img/logo.png" alt="LOGO FIRMY ZDUN - ZDUNTRI.PL" className="logo"/></NavbarBrand>
                <NavbarToggler onClick={e => { this.toggle.bind(this, e); }} aria-label="Otwórz rozwijane menu"/>
                <Collapse isOpen={this.state.isOpen} navbar>
                    <Nav style={navClasses} navbar>
                        <NavItem>
                            <NavLink className={navLinkClasses} href="#start" onClick={e => this.hideMenu(e)}>START</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={navLinkClasses} href="#about_us" onClick={this.hideMenu}>KIM JESTEŚMY</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={navLinkClasses} href="#offer" onClick={this.hideMenu}>OFERTA</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={navLinkClasses} href="#team" onClick={this.hideMenu}>TEAM</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={navLinkClasses} href="#footer" onClick={this.hideMenu}>KONTAKT</NavLink>
                        </NavItem>
                        <NavItem className="text-center text-md-left">
                            <button
                                className="navSocial px-2 bg-transparent border-0"
                                aria-label="Przejdź do facebooka"
                                onClick={function() { window.open('https://www.facebook.com/zduntriathlonteam/', '_blank'); }}>
                                <i className="fab fa-facebook"></i>
                            </button>
                        </NavItem>
                        <NavItem className="text-center text-md-left">
                            <button
                                className="navSocial px-2 bg-transparent border-0"
                                aria-label="Przejdź do instagrama"
                                onClick={function() { window.open('https://www.instagram.com/zduntri.team/', '_blank'); }}>
                                <i className="fab fa-instagram"></i>
                            </button>
                        </NavItem>
                    </Nav>
                </Collapse>
            </Navbar>
        );
    }
}