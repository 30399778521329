import React, { Component } from "react";
import JoinButton from "./JoinButton";
import "./AboutUs.css";

export default class AboutUs extends Component {
    render() {
        return (
            <div className="container-fluid" id={this.props.id}>
                <div className="background_sign pb-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <h1 className="display-md-4 font-weight-bold own_color py-4 mt-3">KIM JESTEŚMY?</h1>
                                <p>
                                    ZdunTri.pl to zespół pasjonatów triathlonu, biegania oraz kolarstwa szosowego.
                                    Jeśli uprawiasz jeden z tych sportów lub chcesz zacząć, ale nie wiesz, jak, dołącz do naszego teamu.
                                </p>
                                <p>
                                    Zapewnimy Ci <b>profesjonalną opiekę trenerską</b> i wsparcie silnie zmotywowanej grupy, z którą sięgniesz po <b>sportowy sukces</b>
                                    – jakkolwiek go definiujesz.
                                    <br/><br/>
                                    Trafisz pod skrzydła doświadczonych trenerów i aktywnych, utytułowanych zawodników.
                                    Stworzymy dla Ciebie <b>indywidualny program ćwiczeń</b> i zapewnimy dostęp do kompleksowych treningów grupowych na każdym poziomie zaawansowania.
                                    <br/><br/>
                                    Atmosfera koleżeńskiej rywalizacji sprawi, że poczujesz pełnię mocy na każdym pokonywanym z nami kilometrze!
                                </p>
                                <p>
                                    Pierwszy trening jest zupełnie za darmo. Przyjdź i przekonaj się bez zobowiązań, jak działa ZdunTri Team. <b>Dołącz do nas!</b>
                                </p>
                            </div>
                            <div className="col-12 col-lg-6">
                                <h4 className="own_color text-center pt-5 pb-2 mt-3">JAK DOŁĄCZYĆ?</h4>
                                <div className="row">
                                    <div className="col-2 coloredList text-right">
                                        <ul>
                                            <li>1</li>
                                            <li>2</li>
                                            <li>3</li>
                                            <li>4</li>
                                            <li>5</li>
                                            <li className="full"></li>
                                        </ul>
                                    </div>
                                    <div className="col-10">
                                        <ul className="nextTo">
                                            <li>Wypełnij formularz kontaktowy lub po prostu wpadnij na najbliższy trening – informacja powyżej.</li>
                                            <li>W ciągu 24 godzin od przesłania formularza odezwiemy się do Ciebie i umówimy na spotkanie.</li>
                                            <li>Na spotkaniu ustalimy cele i zasady współpracy.</li>
                                            <li>Jeśli wolisz, możesz z nami pracować on-line.</li>
                                            <li>Dajemy Ci 100% wolności wyboru. Nie wiążesz się zbędnymi umowami. Sam decydujesz, kiedy rozpoczynasz i kończysz współpracę, oraz to, jaki pakiet wybierasz. Prosto i bez haczyków.</li>
                                            <li className="fullButton pt-3 pt-md-0"><JoinButton /></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="background_sign_bottom pb-5">
                    <div className="container">
                        <h2 className="mt-4 font-weight-bold text-center">TRENUJ Z NAJLEPSZYMI - NASI TRENERZY</h2>
                        <div className="row pt-4 mt-4">
                            <div className="col-12 col-md-3 text-center text-md-left">
                                <img src="/img/aboutus1.png" alt="DAMIAN ZDUN - ZDJĘCIE" />
                            </div>
                            <div className="col-12 col-md-9 my-auto pt-4 pt-md-0">
                                <p className="text-justify">
                                    <b>Damian Zdun</b> - trener ZdunTri.pl. Przygodę ze sportem rozpoczął w Kaliskim Towarzystwie Kolarskim,
                                    aby następnie spełnić marzenia i zacząć starty w triathlonie. Na koncie ma m.in. starty w Młodzieżowych
                                    Mistrzostwach Polski w triathlonie i kolarstwie. Doświadczenie zawodnicze i trenerskie w 100% przekłada
                                    na pracę z podopiecznymi. Inicjator akcji otwartych treningów biegowych w Kaliszu.
                                </p>
                            </div>
                        </div>
                        <div className="row pb-4 mt-4">
                            <div className="col-12 col-md-8 my-auto pt-4 pt-md-0 order-1 order-md-0">
                                <p className="text-justify">
                                    <b>Konrad Zdun</b> - czynny zawodnik triathlonu. Przygodę ze sportem rozpoczął już wieku 10 lat,
                                    w sekcji pływackiej klubu UKS 9 Kalisz, którego barwy reprezentował przez cały okres startów w kategoriach
                                    młodzieżowych. Multimedalista Mistrzostw Polski w triathlonie, uczestnik Mistrzostw Europy i zawodów o Puchar
                                    Europy w triathlonie.
                                </p>
                            </div>
                            <div className="col-12 col-md-3 text-center text-md-right order-0 order-md-1">
                                <img src="/img/aboutus2.png" alt="DAMIAN ZDUN - ZDJĘCIE" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}